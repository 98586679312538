.find-research {
  &__title {
    @apply font-bold text-3xl leading-10 tracking-wide text-brand-navyblue;
  }

  &__sub-title {
    @apply mt-2 leading-7 tracking-wide items-center;
  }
  &__phone {
    @apply text-brand-cyan font-semibold text-lg flex mt-7;

    p {
      @apply ml-2;
    }
  }
}
