.nav-contact {
  @apply w-full bg-brand-navyblue h-10 hidden sm:flex;

  &__container {
    @apply w-full h-full flex items-center;
  }

  &__item {
    @apply flex items-center h-fit py-1 px-3 text-brandWhite text-sm ml-3;

    p {
      @apply ml-2;
    }
  }

  .nav-contact__item:hover {
    @apply shadow-lg bg-brandWhite rounded-lg text-charcoal transition-all duration-500;
  }
}

.nav-bar {
  @apply w-full z-10 sticky top-0 bg-brandWhite shadow-md;

  &__nav {
    @apply hidden transition-all duration-500 md:flex;
  }

  &__nav2 {
    @apply absolute right-0 top-0 bg-brand-navyblue text-brandWhite py-5 px-5 w-3/5 rounded-l-xl h-80 transition-all duration-500;
  }

  &__content-container {
    @apply w-full h-16;
  }

  &__content {
    @apply w-full h-full flex justify-between items-center;

    &__logo {
      @apply w-52 ml-8 h-11 overflow-hidden relative font-semibold text-3xl leading-10 text-brand-navyblue;
    }
  }

  &__list {
    @apply flex flex-col sm:flex-col md:flex-row;
  }

  &__list-item {
    @apply mb-5 md:mb-0 mr-8 text-lg font-semibold leading-5 tracking-wide relative;
  }
}

.nav-bar__list-item::after {
  @apply content-[""] bg-brand-cyan w-0 h-[3px] absolute left-0 -bottom-[6px] transition-all duration-500;
}

.nav-bar__list-item:hover::after {
  @apply w-full;
}

.nav-bar__content__logo:before {
  content: "[";
  @apply left-0 text-brand-cyan;
}

.nav-bar__content__logo:after {
  content: "]";
  @apply absolute right-0 text-brand-cyan;
}

.nav-bar__content__logo:after,
.nav-bar__content__logo:before {
  position: absolute;

  top: 0;

  font-size: 35px;

  line-height: 40px;

  -webkit-animation-name: opacity;

  -webkit-animation-duration: 2s;

  -webkit-animation-iteration-count: infinite;

  animation-name: opacity;

  animation-duration: 2s;

  animation-iteration-count: infinite;
}

.nav-bar__content__list {
  margin-top: 3px;

  padding-left: 25px;

  text-align: left;

  list-style: none;

  -webkit-animation-name: change;

  -webkit-animation-duration: 10s;

  -webkit-animation-iteration-count: infinite;

  animation-name: change;

  animation-duration: 10s;

  animation-iteration-count: infinite;
}

.nav-bar__content__list-item {
  line-height: 40px;

  margin: 0;
}

@-webkit-keyframes opacity {
  0%,
  100% {
    opacity: 0;
  }

  50% {
    opacity: 1;
  }
}

@-webkit-keyframes change {
  0%,
  12.66%,
  100% {
    transform: translate3d(0, 0, 0);
  }

  16.66%,
  29.32% {
    transform: translate3d(0, -25%, 0);
  }

  33.32%,
  45.98% {
    transform: translate3d(0, -50%, 0);
  }

  49.98%,
  62.64% {
    transform: translate3d(0, -75%, 0);
  }

  66.64%,
  79.3% {
    transform: translate3d(0, -50%, 0);
  }

  83.3%,
  95.96% {
    transform: translate3d(0, -25%, 0);
  }
}

@keyframes opacity {
  0%,
  100% {
    opacity: 0;
  }

  50% {
    opacity: 1;
  }
}

@keyframes change {
  0%,
  12.66%,
  100% {
    transform: translate3d(0, 0, 0);
  }

  16.66%,
  29.32% {
    transform: translate3d(0, -25%, 0);
  }

  33.32%,
  45.98% {
    transform: translate3d(0, -50%, 0);
  }

  49.98%,
  62.64% {
    transform: translate3d(0, -75%, 0);
  }

  66.64%,
  79.3% {
    transform: translate3d(0, -50%, 0);
  }

  83.3%,
  95.96% {
    transform: translate3d(0, -25%, 0);
  }
}
