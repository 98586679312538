.services {
  &__insilico {
    p {
      @apply text-xl mb-3 font-medium;
    }
  }

  &__card-layout {
    @apply grid grid-cols-2 md:grid-cols-4 gap-4 mt-10;
  }
}
