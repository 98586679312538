.blogdetail {
  @apply grid grid-cols-1 md:grid-cols-2 gap-5;

  &__image {
    @apply shadow-xl;
  }

  &__image img {
    @apply w-full h-[400px] rounded-md;
  }

  &__description {
  }
}
