.footer {
  @apply flex items-start flex-col;

  &__contact-media {
    @apply flex flex-col;

    .contact-media-title {
      @apply font-bold text-lg text-left mb-1;
    }

    .contact-media-item {
      @apply flex items-center w-full mb-4;
    }

    .contact-media-contact {
      @apply ml-2;
    }
  }

  &__quick-links {
    @apply flex flex-col;

    .quick-links-title {
      @apply font-bold text-lg text-left mb-1;
    }

    .quick-links-ul {
      &__li {
        @apply text-sm hover:ml-2 text-neutral-500 transition-all duration-700 font-medium mb-2;
      }
    }
  }

  &__other-links {
    .other-link-title {
      @apply font-bold text-lg text-left mb-1;
    }
    .other-link-buttons {
      @apply flex flex-col;
    }
  }

  &__social-media {
    @apply flex justify-evenly;

    .social-media-icon {
      @apply rounded-full p-2 w-fit h-fit bg-brand-cyan text-brandWhite hover:scale-125 transition-all duration-500;
    }
  }
}
