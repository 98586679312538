.publication {
  @apply flex flex-col justify-center items-center shadow-lg bg-brandWhite p-3;

  &__title {
    @apply font-bold text-xl md:text-3xl leading-10 tracking-wide text-brand-navyblue mb-5;
  }

  &__para{
    @apply leading-8 mb-5;
  }

  &__author-container {
    @apply bg-brandWhite p-2 rounded-md w-11/12 mt-2 shadow-lg;
  }

  &__author {
    @apply text-xs text-tertiary font-semibold;
  }
}
