.research-card {
  @apply min-h-[10rem] w-full border-b-4 border-b-brand-cyan rounded-md bg-brandWhite px-4 py-3 shadow-md mb-5;

  &__title {
    @apply font-semibold text-lg mb-3;
  }

  &__sub-title {
    @apply mb-3;
  }
}
