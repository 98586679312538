// .ceo-section {
//   &__title {
//     @apply leading-10 tracking-wide text-brand-cyan;
//   }

//   &__sub-title {
//     @apply mt-2 leading-8 tracking-wide items-center font-bold text-3xl w-[500px];
//   }

//   &__img {
//     @apply h-[300px] w-[500px] rounded-lg;
//   }

//   &__phone {
//     @apply text-brand-cyan font-semibold text-lg flex mt-7;

//     p {
//       @apply ml-2;
//     }
//   }

//   &__para-container {
//     @apply flex  items-center text-sm mb-5;

//     p {
//       @apply ml-4;
//     }
//   }
// }

// this is a comment 

.ceo-section__title {
  line-height: 2.5rem; /* Adjusted for `leading-10` */
  letter-spacing: 0.025em; /* Adjusted for `tracking-wide` */
  color: #00bcd4; /* Replace with the actual color value of `text-brand-cyan` */
}

.ceo-section__sub-title {
  margin-top: 0.5rem; /* Adjusted for `mt-2` */
  line-height: 2rem; /* Adjusted for `leading-8` */
  letter-spacing: 0.025em; /* Adjusted for `tracking-wide` */
  align-items: center; /* Adjusted for `items-center` */
  font-weight: bold; /* Adjusted for `font-bold` */
  font-size: 1.875rem; /* Adjusted for `text-3xl` */
  width: 500px; /* Adjusted for `w-[500px]` */
}

.ceo-section__img {
  height: 400px; /* Adjusted for `h-[300px]` */
  width: 500px; /* Adjusted for `w-[500px]` */
  border-radius: 0.5rem; /* Adjusted for `rounded-lg` */
}

.ceo-section__phone {
  color: #00bcd4; /* Replace with the actual color value of `text-brand-cyan` */
  font-weight: 600; /* Adjusted for `font-semibold` */
  font-size: 1.125rem; /* Adjusted for `text-lg` */
  display: flex; /* Adjusted for `flex` */
  margin-top: 1.75rem; /* Adjusted for `mt-7` */
}

.ceo-section__phone p {
  margin-left: 0.5rem; /* Adjusted for `ml-2` */
}

.ceo-section__para-container {
  display: flex; /* Adjusted for `flex` */
  align-items: center; /* Adjusted for `items-center` */
  font-size: 0.875rem; /* Adjusted for `text-sm` */
  margin-bottom: 1.25rem; /* Adjusted for `mb-5` */
}

.ceo-section__para-container p {
  margin-left: 1rem; /* Adjusted for `ml-4` */
}
