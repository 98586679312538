.about-us {
  &__title {
    @apply leading-10 tracking-wide text-brand-cyan;
  }

  &__sub-title {
    @apply mt-2 leading-7 tracking-wide items-center font-bold text-3xl;
  }
  &__phone {
    @apply text-brand-cyan font-semibold text-lg flex mt-7;

    p {
      @apply ml-2;
    }
  }

  &__para-container {
    @apply flex  items-center text-sm mb-5;

    p {
      @apply ml-4;
    }
  }
}
